import {AbstractControl, ValidationErrors} from "@angular/forms";
import striptags from "striptags";
import {FormlyFieldConfig} from "@ngx-formly/core/lib/models/fieldconfig";

// @ts-ignore unused variable
export function ContentRequiredValidator(control: AbstractControl, field: FormlyFieldConfig, options?: {
  [id: string]: any;
}): ValidationErrors | null {
  if (!control.value) {
    return { contentRequired: true }
  }

  const value = striptags(control.value).trim()

  if (value.length === 0) {
    return { contentRequired: true }
  }

  return null
}
