import {
  afterNextRender,
  ChangeDetectionStrategy,
  ChangeDetectorRef, Component, EnvironmentInjector,
  HostBinding,
  inject, PLATFORM_ID,
  Renderer2, runInInjectionContext,
} from '@angular/core';
import {
  ActivationStart, NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import {AppSettingsStore} from "../core/app-settings.store";
import {UserMenuComponent} from "../layout/navigation/user-menu/user-menu.component";
import {isPlatformBrowser} from "@angular/common";
import {
  MatSidenav,
  MatSidenavContainer, MatSidenavContent
} from "@angular/material/sidenav";
import {MatToolbar} from "@angular/material/toolbar";
import {MatButton} from "@angular/material/button";
import {ToggleDarkModeComponent} from "../layout/navigation/toggle-dark-mode/toggle-dark-mode.component";
import {ToolbarComponent} from "../layout/navigation/toolbar/toolbar.component";
import {SidenavComponent} from "../layout/navigation/sidenav/sidenav.component";
import {SeoService} from "../content/seo.service";
import {FooterComponent} from "../layout/navigation/footer/footer.component";
import {SidenavStateStore} from "../layout/navigation/toolbar/toolbar.state";
import {LoadingBarComponent} from "../layout/loading-bar/loading-bar.component";
import {UserAuthState} from "../core/auth/user.store";
import {UmamiService} from "./umami.service";
import {AuthData} from "../user/user";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, UserMenuComponent, RouterLink, MatSidenav, MatSidenavContainer, MatSidenavContent, MatToolbar, MatButton, ToggleDarkModeComponent, ToolbarComponent, SidenavComponent, FooterComponent, LoadingBarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  readonly store = inject(UserAuthState);
  readonly sidenavStore = inject(SidenavStateStore);

  private _router = inject(Router)
  private _seoService = inject(SeoService)

  private _renderer = inject(Renderer2)
  private _platformId = inject(PLATFORM_ID)

  public appSettings = inject(AppSettingsStore)


  @HostBinding('class.dark') get darkMode() {
    let darkMode = this.appSettings.darkMode();

    if (isPlatformBrowser(this._platformId)) {
      if (darkMode) {
        this._renderer.addClass(document.documentElement, 'dark')
        this._renderer.removeClass(document.documentElement, 'light')
      } else {
        this._renderer.addClass(document.documentElement, 'light')
        this._renderer.removeClass(document.documentElement, 'dark')
      }
    }

    return darkMode
  }

  private _cd = inject(ChangeDetectorRef)
  private _injector = inject(EnvironmentInjector)

  constructor() {

    afterNextRender(() => {
      runInInjectionContext(this._injector, () => {
        let umamiService = inject(UmamiService);

        umamiService.loadUmami(this._renderer)

        import('../core/auth/auth.store').then((s) => {
          runInInjectionContext(this._injector, () => {
            const service = inject(s.AuthStore)

            service.getIdentity().then(() => {
              this._cd.detectChanges()
              setInterval(() => {
                service.getIdentity().then((authState: AuthData) => {
                  // @ts-ignore
                  umamiService.getUmami()!!.subscribe({
                    next: (umami: any) => {
                      // @ts-ignore
                      if (authState.isAuthenticated) {
                        console.log(authState);
                        const user = authState.user;

                        umami.identify(
                          { id: user?._id, email: user?.email, username: user?.username }
                        )
                      }
                    },
                    error: (err: any) => {
                      console.error(err)
                    }
                  })
                });
              }, 30000)
            });
          })
        });
      });

      // effect(() => {
      //   this.appSettings.saveSettings();
      // });
      this._router.events.subscribe(data => {
        if (data instanceof ActivationStart) {
          let seo = data.snapshot.data['seo']

          if (seo) {
            this._seoService.setMetadata(seo)
          }

          this.sidenavStore.close();
        }

        if (data instanceof NavigationEnd) {
          this._seoService.canonicalUrl(data.urlAfterRedirects)
        }
      });
    });
  }
}
