import { HttpInterceptorFn } from '@angular/common/http';
import {inject} from "@angular/core";
import {LoadingStateStore} from "./loading.state";
import {finalize} from "rxjs";

export const loadingBarInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingState = inject(LoadingStateStore)

  loadingState.show()

  return next(req).pipe(
    finalize(() => {
      loadingState.hide()
    })
  );
};
