import { HttpInterceptorFn } from '@angular/common/http';
import {inject, InjectionToken} from "@angular/core";

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

export const baseUrlInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.startsWith('http://') || req.url.startsWith('https://')) {
    return next(req);
  }

  let baseUrl = inject(API_BASE_URL)

  if (baseUrl.endsWith('/') && req.url.startsWith('/')) {
    baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  }

  req = req.clone({
    url: baseUrl + req.url
  });

  return next(req);
};
