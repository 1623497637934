import {patchState, signalStore, withMethods, withState} from "@ngrx/signals";


export interface LoadingState {
  isLoading: boolean
}

const defaultState = {
  isLoading: false
} as LoadingState

export const LoadingStateStore = signalStore({providedIn: 'root'},
  withState<LoadingState>(defaultState),
  withMethods((store) => ({
      show() {
        patchState(store, {isLoading: true});
      },
      hide() {
        patchState(store, {isLoading: false});
      }
    })
  )
)


