import {Component, ChangeDetectionStrategy, ChangeDetectorRef, inject, AfterViewInit} from '@angular/core';
import {FormlyModule} from '@ngx-formly/core';
import {FieldType} from '@ngx-formly/material/form-field';
import {MatInput, MatSuffix} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'formly-field-password',
  template: `
    <input
      matInput
      [type]="type"
      [required]="required"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="formControl"
      [formlyAttributes]="field"
    />

    <mat-icon [svgIcon]="this.icon" matSuffix (click)="togglePasswordVisibility()"></mat-icon>
  `,
  styleUrls: ['./password.field.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatInput,
    ReactiveFormsModule,
    FormlyModule,
    MatIcon,
    MatSuffix
  ],
  standalone: true
})
export class PasswordFieldType extends FieldType<any> implements AfterViewInit{
  type = 'password';
  icon = 'visibility';

  private _cdRef = inject(ChangeDetectorRef)

  ngAfterViewInit() {
    this.props['addonRight'] = {
      'icon': this.icon,
      onClick: () => {
        this.togglePasswordVisibility();
      }
    }
  }

  togglePasswordVisibility() {
    this.type = this.type === 'password' ? 'text' : 'password';
    this.icon = this.type === 'password' ? 'visibility' : 'visibility_off';

    this._cdRef.markForCheck()
  }
}
