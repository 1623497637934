import {Component, inject} from '@angular/core';
import {LoadingStateStore} from "./loading.state";
import {MatProgressBar} from "@angular/material/progress-bar";

@Component({
  selector: 'app-loading-bar',
  standalone: true,
  imports: [
    MatProgressBar
  ],
  templateUrl: './loading-bar.component.html',
  styleUrl: './loading-bar.component.scss'
})
export class LoadingBarComponent {
  private state = inject(LoadingStateStore)
  readonly isLoading = this.state.isLoading
}
