import {AbstractControl, ValidationErrors} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core/lib/models/fieldconfig";

// @ts-ignore unused variable
export function PasswordValidator(control: AbstractControl, field: FormlyFieldConfig, options?: {
  [id: string]: any;
}): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const password = control.value;

  const hasMinLen = password.length >= 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#~$%^&*(),.?":{}|<>]/.test(password);

  const isValid = hasMinLen && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;

  if (isValid) {
    return null;
  }

  return { password: { message: 'Hasło musi zawierać co najmniej jedną wielką literę, jedną małą literę, jedną cyfrę i jeden znak specjalny.' } };
}
