import {Component, inject, Signal} from '@angular/core';
import {MatToolbar} from "@angular/material/toolbar";
import {ToggleDarkModeComponent} from "../toggle-dark-mode/toggle-dark-mode.component";
import {UserMenuComponent} from "../user-menu/user-menu.component";
import {RouterLink} from "@angular/router";
import {SidenavStateStore} from "./toolbar.state";
import {AppLogoComponent} from "../../../ui-kit/components/app-logo/app-logo.component";
import {UserAuthState} from "../../../core/auth/user.store";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    MatToolbar,
    ToggleDarkModeComponent,
    UserMenuComponent,
    RouterLink,
    AppLogoComponent,
    MatIcon
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent {
  private _authStore = inject(UserAuthState)
  private _sidenavState = inject(SidenavStateStore)

  readonly isOpened: Signal<boolean> = this._sidenavState.opened
  readonly isLoggedIn: Signal<boolean> = this._authStore.isAuthenticated

  menuItems = [
    {label: 'Wiersze', link: '/utwory/wiersze'},
    {label: 'Opowiadania', link: '/utwory/opowiadania'},
    {label: 'Artykuły', link: '/utwory/artykuly'},
    {label: 'Felietony', link: '/utwory/felietony'},
    // {label: 'Książki', link: '/utwory/ksiazki'},
  ];

  toggleSidenav() {
    this._sidenavState.toggle()
  }
}
