import {
  Component,
  inject, input,
  Signal,
} from '@angular/core';
import {AppSettingsStore} from "../../../core/app-settings.store";
import {MatIcon} from "@angular/material/icon";

export type ToggleDarkModeMode = 'switch' | 'button'

@Component({
  selector: 'app-toggle-dark-mode',
  standalone: true,
  imports: [
    MatIcon
  ],
  templateUrl: './toggle-dark-mode.component.html',
  styleUrl: './toggle-dark-mode.component.scss'
})
export class ToggleDarkModeComponent {
  private _appSettings = inject(AppSettingsStore)

  mode = input<ToggleDarkModeMode>('button', {
    alias: 'mode'
  })

  readonly darkMode: Signal<boolean> = this._appSettings.darkMode

  toggle() {
    this._appSettings.toggleDarkMode()
  }
}
