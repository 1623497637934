  <div class="relative">
    <button [cdkMenuTriggerFor]="accountMenu"
            class="icon-btn"
            type="button" aria-label="Menu użytkownika">

      <mat-icon [svgIcon]="'mat_outline:person'"></mat-icon>

      <ng-template #accountMenu>
        <div cdkMenu class="user-menu">
          <div>
            <div class="user-menu-wrapper">
              <div>
                <div class="user-details">
                  <div class="user-avatar">
                    <img fill sizes="10vw" [loaderParams]="{'preset': 'avatar-small'}" [ngSrc]="user()|avatarPath" [attr.alt]="user()|displayName" [loading]="'lazy'">
                  </div>
                  <div class="user-data">
                    <h4>{{ user() | displayName }}</h4>
                    <p>{{ user()?.email }}</p>
                  </div>
                </div>
              </div>
              <div class="menu-separator"></div>

              <a [routerLink]="'/konto/utwory'" cdkMenuItem>
                <div class="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                  <svg
                    width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M8 12.2H15" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M8 16.2H12.38" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z" stroke="currentColor"
                          stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path
                      d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44002 4.67 4.20002 8 4.02002"
                      stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div class="ml-4">
                  <p class="text-sm font-medium ">Moje utwory</p>
                </div>
              </a>
              <a [routerLink]="'/konto'" cdkMenuItem>
                <div class="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                  <mat-icon [svgIcon]="'mat_outline:settings'"></mat-icon>
                </div>
                <div class="ml-4">
                  <p class="text-sm font-medium ">Ustawienia</p>
                </div>
              </a>

              <div class="menu-separator"></div>

              <div
                class="flex items-center justify-between p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                <div class="flex items-center">
                  <div class="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                    <mat-icon [svgIcon]="'mat_outline:dark_mode'"></mat-icon>
                  </div>
                  <div class="ml-4">
                    <p class="text-sm font-medium ">Tryb ciemny</p>
                  </div>
                </div>
                <div class="inline-flex">
                  <app-toggle-dark-mode [mode]="'switch'"></app-toggle-dark-mode>
                </div>
              </div>
              <a href="#" (click)="logout($event)">
                <div class="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                  <mat-icon [svgIcon]="'mat_outline:logout'"></mat-icon>
                </div>
                <div class="ml-4">
                  <a href="#" (click)="logout($event)" class="text-sm font-medium ">Wyloguj</a>
                </div>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </button>
  </div>

  <!--  <div-->
  <!--    style="position: fixed; top: 1px; left: 1px; width: 1px; height: 0px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; border-width: 0px; display: none;">-->
  <!--  </div>-->
