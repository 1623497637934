import {Component, inject, Signal} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {SidenavStateStore} from "../toolbar/toolbar.state";
import {RouterLink} from "@angular/router";
import {ToggleDarkModeComponent} from "../toggle-dark-mode/toggle-dark-mode.component";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [MatSidenav, RouterLink, ToggleDarkModeComponent, MatIcon],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss'
})
export class SidenavComponent {
  sidenavState = inject(SidenavStateStore)

  readonly isOpened: Signal<boolean> = this.sidenavState.opened

  menuItems = [
    {label: 'Wiersze', link: '/utwory/wiersze'},
    {label: 'Opowiadania', link: '/utwory/opowiadania'},
    {label: 'Artykuły', link: '/utwory/artykuly'},
    {label: 'Felietony', link: '/utwory/felietony'},
    // {label: 'Książki', link: '/utwory/ksiazki'},
  ];

  closeSidenav() {
    this.sidenavState.close()
  }
}
