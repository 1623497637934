import {AbstractControl, ValidationErrors} from "@angular/forms";
import striptags from "striptags";
import {FormlyFieldConfig} from "@ngx-formly/core";

// @ts-ignore unused variable
export function ContentMinMaxLengthValidator(control: AbstractControl, field: FormlyFieldConfig, options?: {
  [id: string]: any;
}): ValidationErrors | null {

  if (!control.value) {
    return null
  }

  if (!(options) || options['min'] == undefined && options['max'] == undefined) {
    return null
  }

  const value = striptags(control.value).trim()

  let actualLength = value.length;

  if (options['min'] && actualLength < options['min']) {
    return {contentMinLength: {min: options['min'], current: actualLength}}
  }

  if (options['max'] && actualLength > options['max']) {
    return {contentMaxLength: {max: options['max'], current: actualLength}}
  }

  return null
}
