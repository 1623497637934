import {HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {REQUEST} from "../../express.tokens";
import {inject} from "@angular/core";

export const headersInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const request = inject(REQUEST, { optional: true });
  // const response = inject(RESPONSE, { optional: true });

  if (!request) {
    return next(req);
  }

  // console.log("new incoming request",JSON.stringify(request.headers));

  for (let header in request.headersDistinct) {
    req.headers.set(header, request.headersDistinct[header] || "");
  }

  //? SSR Should forward cookies from the original request.
  req = req.clone({
    withCredentials: true,
    // headers: req.headers.set("Cookie", request?.headers?.cookie || ""),
  });

  // console.log("new outgoing request",JSON.stringify(req.headers));

  return next(req);
  //
  // return next(modifiedRequest).pipe(
  //   tap((res) => {
  //     //? SSR Should forward Set-Cookie headers to the client
  //     if (
  //       response &&
  //       res.type == HttpEventType.Response &&
  //       res.headers.get("set-cookie") // &&
  //       // req.url.startsWith(environment.apiUrl)
  //     ) {
  //       console.log(response.getHeaders());
  //       response.setHeader(
  //         "set-cookie",
  //         res.headers.getAll("set-cookie") || []
  //       );
  //     }
  //   })
  // );
};
