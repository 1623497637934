import {
  Component
} from '@angular/core';
import {FieldTypeConfig, FormlyModule} from '@ngx-formly/core';
import {FieldType, FormlyMaterialModule} from '@ngx-formly/material';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {QuillModule} from "ngx-quill";
import Quill from "quill";
import {MatError} from "@angular/material/form-field";
import {NgIf} from "@angular/common";
@Component({
  selector: 'content-editable',
  templateUrl: './content-editable.component.html',
  styleUrls: ['./content-editable.component.scss'],
  imports: [
    FormlyModule, ReactiveFormsModule, FormsModule, QuillModule, FormlyMaterialModule, MatError, NgIf
  ],
  standalone: true
})
export class ContentEditableComponent extends FieldType<FieldTypeConfig> {

  // // @ts-ignore
  // private editor: any = null;
  //
  // @ViewChild('editor') editorRef: ElementRef|null = null;

  // async ngAfterViewInit() {
  //     if (isPlatformBrowser(this.platformId)) {
  //       const m = await import('quill');
  //       const Quill = (
  //         // seems like esmodules have nested "default"
  //         (m.default as any)?.default ?? m.default ?? m
  //       ) as any;
  //

  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{'header': 2}, {'header': 3}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}], // {'list': 'check'}],
    [{'align': []}],
    ['clean']                                         // remove formatting button
  ];

  formats = [
    'bold',
    'italic',
    'strike',
    'underline',
    'header',
    'indent',
    'list',
    'align',
  ]

  // @ts-ignore
  modules = {
    toolbar: this.toolbarOptions,
    // clipboard: {
    //   allowed: {
    //     tags: ['p', 'h2', 'h3', 'strong', 'b', 'em', 'u', 's', 'ul', 'ol', 'li', 'br'],
    //   },
    // }
  }
  //
  //       this.zone.runOutsideAngular(() => {
  //         this.editor = new Quill(this.editorRef?.nativeElement, {
  //           modules: {
  //             toolbar: toolbarOptions
  //           },
  //           theme: 'snow'
  //           // theme: 'bubble'
  //         });
  //       })
  //     }
  // }
  async ngOnInit() {
    // @ts-ignore
  }

  onCreate($event: Quill) {

    // @ts-ignore
    $event.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => this.pastePlainText(delta))
    // @ts-ignore
    $event.clipboard.addMatcher('IMG', (node, delta) => this.pastePlainText(delta))
    // @ts-ignore
    $event.clipboard.addMatcher('PICTURE', (node, delta) => this.pastePlainText(delta))
  }

  private pastePlainText(delta: any) {
    delta.ops = delta.ops.reduce(
      (previousValue: any, currentValue: any) =>
        currentValue.insert && typeof currentValue.insert === 'string'
          ? previousValue.concat({ insert: currentValue.insert })
          : previousValue,
      []
    );

    return delta;
  }
}
