import {ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {UserService} from "../user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HomepageHeroComponent} from "../../layout/base-layout/hero/homepage-hero.component";
import {
  ProfileSettingsHeaderComponent
} from "../settings-layout/profile-settings-header/profile-settings-header.component";
import {User} from "../user";
import {ContentListComponent} from "../../content/content-list/content-list.component";
import {DisplayNamePipe} from "../pipes/display-name.pipe";
import {NgIf} from "@angular/common";
import {ImgProxyLoader} from "../../core/util/image-loader";
import {AvatarPathPipe} from "../pipes/avatar-path.pipe";
import {SeoService} from "../../content/seo.service";

@Component({
  selector: 'app-public-profile',
  standalone: true,
  imports: [
    HomepageHeroComponent,
    ProfileSettingsHeaderComponent,
    ContentListComponent,
    DisplayNamePipe,
    NgIf
  ],
  templateUrl: './public-profile.component.html',
  styleUrl: './public-profile.component.scss'
})
export class PublicProfileComponent implements OnInit {
  private _userService = inject(UserService)
  private _route = inject(ActivatedRoute)
  private _cdk = inject(ChangeDetectorRef)

  user: User|null = null

  private _router = inject(Router)
  private _seoService = inject(SeoService)

  async ngOnInit() {
    this._route.params.pipe().subscribe(params => {
      let param = decodeURIComponent(params['username']);

      this._userService.getProfile(param).subscribe({
        next: user => {
          this.user = user
          this._seoService.image(
            ImgProxyLoader({
              src: new AvatarPathPipe().transform(user),
              loaderParams: {
                preset: 'avatar-original'
              }
            })
          )
          this._cdk.detectChanges()
        },
        error: error => {
          if (error.status === 404) {
            this._router.navigate(['/errors/404'])
          }
        }
      })
    })
  }
}
