import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding, withEnabledBlockingInitialNavigation, withInMemoryScrolling,
  withPreloading,
  withRouterConfig, withViewTransitions
} from '@angular/router';
import localePl from '@angular/common/locales/pl';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideIcons} from "../core/icons/icons.provider";
import {provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {provideNotifications} from "../core/notification/notification.provider";
import {FormlyModule} from "@ngx-formly/core";
import {ValidationMessages} from "../core/forms/validation.service";
import {ContentRequiredValidator} from "../core/forms/validation/content-required.validator";
import {FieldMatchValidator} from "../core/forms/validation/field-match.validator";
import {ContentMinMaxLengthValidator} from "../core/forms/validation/content-min-max-length.validator";
import {loadingBarInterceptor} from "../layout/loading-bar/loading-bar.interceptor";
import {retryErrorInterceptor} from "../core/interceptor/retry-error.interceptor";
import {API_BASE_URL, baseUrlInterceptor} from "../core/interceptor/base-url.interceptor";
import {environment} from "../environments/environment";
import {APP_BASE_HREF, IMAGE_LOADER, registerLocaleData} from "@angular/common";
import {ImgProxyLoader} from "../core/util/image-loader";
import {TitleFieldComponent} from "../core/forms/fields/title-field/title-field.component";
import {ContentEditableComponent} from "../core/forms/fields/contenteditable/content-editable.component";
import {QuillModule} from "ngx-quill";
import {headersInterceptor} from "../core/interceptor/cookie.interceptor";
import {provideAuth} from "../core/auth/auth.provider";
import {PasswordValidator} from "../core/forms/validation/password.validator";
import {PasswordFieldType} from "../core/forms/fields/password/password.field";
import {FormlyWrapperAddons} from "../core/forms/fields/addon.wrapper";
import {addonsExtension} from "../core/forms/fields/addon.extention";

registerLocaleData(localePl);


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      }),

      withPreloading(PreloadAllModules),
      withComponentInputBinding(),
      withViewTransitions({

      }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload'
      })
    ),
    provideAnimations(),
    { provide: IMAGE_LOADER, useFactory: () => ImgProxyLoader },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: API_BASE_URL, useValue: environment.api_url },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    provideHttpClient(withFetch(), withInterceptors([headersInterceptor, baseUrlInterceptor, loadingBarInterceptor, retryErrorInterceptor])),
    provideClientHydration(),
    provideAnimations(),
    provideIcons(),
    provideAuth(),
    provideNotifications(),
    {
      // Use the 'fill' appearance on Angular Material form fields by default
      provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'never',
      },
    },
    importProvidersFrom(QuillModule.forRoot()),
    importProvidersFrom(FormlyModule.forRoot({
      wrappers: [
        { name: 'addons', component: FormlyWrapperAddons }
      ],
      extensions: [{ name: 'addons', extension: { onPopulate: addonsExtension } }],
      types: [
        { name: 'contenteditable', component: ContentEditableComponent, wrappers: [] },
        { name: 'title', component: TitleFieldComponent, wrappers: [] },
        { name: 'password', component: PasswordFieldType, wrappers: ['form-field'] },
      ],
      validators: [
        {name: 'password', validation: PasswordValidator },
        {name: 'fieldMatch', validation: FieldMatchValidator},
        {name: 'contentRequired', validation: ContentRequiredValidator},
        {name: 'contentMinMaxLength', validation: ContentMinMaxLengthValidator, options: {min: 3}}
      ],
      validationMessages: [
        {name: 'required', message: 'To pole jest wymagane'},
        {name: 'contentRequired', message: 'To pole jest wymagane'},
        {name: 'contentMinLength', message: ValidationMessages.contentMinLengthValidationMessage},
        {name: 'contentMaxLength', message: ValidationMessages.contentMaxLengthValidationMessage},
        {name: 'minLength', message: ValidationMessages.minLengthValidationMessage},
        {name: 'maxLength', message: ValidationMessages.maxLengthValidationMessage},
        {name: 'email', message: ValidationMessages.invalidEmailMessage},
        {name: 'min', message: ValidationMessages.minValidationMessage},
        {name: 'max', message: ValidationMessages.maxValidationMessage},
        {name: 'pattern', message: ValidationMessages.patternValidationMessage},
        {name: 'fieldMatch', message: ValidationMessages.fieldMatch},
        {name: 'password', message: ValidationMessages.password},
        {name: 'other', message: (err: any, field: any) => ValidationMessages.other(err, field) }
      ],

    })),
]
};
