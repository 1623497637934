import {CanActivateChildFn, CanActivateFn, Router} from "@angular/router";
import {inject, PLATFORM_ID} from "@angular/core";
import {UserAuthState} from "../user.store";
import {isPlatformServer} from "@angular/common";
import {AuthStore} from "../auth.store";

export const AuthGuard: CanActivateFn | CanActivateChildFn = (_route, state) => {
  const router: Router = inject(Router)
  const store = inject(UserAuthState);
  const authStore = inject(AuthStore);
  const platformId = inject(PLATFORM_ID)
  const isServer = isPlatformServer(platformId)

  if (isServer) {
    return true;
  }

  return new Promise((resolve) => {
    authStore.getIdentity().then(() => {

      console.log("AuthGuard", store.isAuthenticated())

      if (!store.isAuthenticated()) {
        const redirectURL = `redirectURL=${state.url}`;
        const urlTree = router.parseUrl(`/logowanie?${redirectURL}`);

        if (isServer) {
          resolve(true)
          return;
        }

        resolve(urlTree)
        return;
      }

      resolve(true)
    })
  })
}
