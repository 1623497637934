import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import {catchError, retry, throwError, timer} from "rxjs";

export const retryErrorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    retry({
      count: 300,
      delay: genericRetryStrategy(),
      resetOnSuccess: false
    }),
    catchError((error: HttpErrorResponse) => {
      return throwError(() => error)
    })
  )
    ;
};

export const genericRetryStrategy = ({
                                       scalingDuration = 100,
                                       includedStatusCodes = [501, 502, 504, 503]
                                     }: {
  scalingDuration?: number,
  includedStatusCodes?: number[]
} = {}) => ((error: any, retryCount: number) => {
  if (
    !includedStatusCodes.find(e => e === error.status)
  ) {
    return throwError(() => error);
  }

  return timer(retryCount * scalingDuration);
});
