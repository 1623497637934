import {patchState, signalStore, withMethods, withState} from "@ngrx/signals";

export const SidenavStateStore = signalStore({providedIn: 'root'},
  withState({
    opened: false
  }),
  withMethods((store) => ({
    toggle() {
      patchState(store, {opened: !store.opened()})
    },
    open() {
      patchState(store, {opened: true})
    },
    close() {
      patchState(store, {opened: false})
    }
  }))
)
