import {Component, computed, inject} from '@angular/core';
import {AppLogoComponent} from "../../../ui-kit/components/app-logo/app-logo.component";
import {RouterLink} from "@angular/router";
import {AppSettingsStore} from "../../../core/app-settings.store";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    AppLogoComponent,
    RouterLink
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  get currentYear() {
    return new Date().getFullYear();
  }

  private _store = inject(AppSettingsStore)

  showConsent = computed(() => {
    return this._store.displayConsent()
  });

  acceptConsent() {
    this._store.acceptConsent();
  }
}
