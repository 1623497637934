import {AbstractControl, ValidationErrors} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core/lib/models/fieldconfig";

// @ts-ignore unused variable
export function FieldMatchValidator(control: AbstractControl, field: FormlyFieldConfig, options?: {
  [id: string]: any;
}): ValidationErrors | null {
  const { password, passwordRepeat } = control.value;

  // avoid displaying the message error when values are empty
  if (!passwordRepeat || !password) {
    return null;
  }

  if (passwordRepeat === password) {
    return null;
  }

  return { fieldMatch: { message: 'Wprowadzone hasła nie są identyczne' } };
}
