import {Component, EnvironmentInjector, inject, runInInjectionContext, Signal} from '@angular/core';
import {CdkMenu, CdkMenuBar, CdkMenuItem, CdkMenuTrigger} from "@angular/cdk/menu";
import {AppSettingsStore} from "../../../core/app-settings.store";
import {ToggleDarkModeComponent} from "../toggle-dark-mode/toggle-dark-mode.component";
import {RouterLink} from "@angular/router";
import {MenuLinkComponent} from "./menu-link/menu-link.component";
import {DisplayNamePipe} from "../../../user/pipes/display-name.pipe";
import {AvatarPathPipe} from "../../../user/pipes/avatar-path.pipe";
import {User} from "../../../user/user";
import {MatIcon} from "@angular/material/icon";
import {UserAuthState} from "../../../core/auth/user.store";
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-user-menu',
  standalone: true,
  imports: [
    CdkMenuBar,
    CdkMenuTrigger,
    CdkMenu,
    ToggleDarkModeComponent,
    RouterLink,
    MenuLinkComponent,
    CdkMenuItem,
    DisplayNamePipe,
    AvatarPathPipe,
    MatIcon,
    NgOptimizedImage
  ],
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
})
export class UserMenuComponent {
  private _appSettings = inject(AppSettingsStore)
  readonly authStore = inject(UserAuthState)

  readonly user: Signal<User|null> = this.authStore.user

  readonly darkMode = this._appSettings.darkMode

  toggleDarkMode() {
    this._appSettings.toggleDarkMode()
  }

  private _injector = inject(EnvironmentInjector)

  logout(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    import('../../../core/auth/auth.store').then((s) => {
      runInInjectionContext(this._injector, () => {
        const service = inject(s.AuthStore)

        service.logout()
      })
    })
  }
}
