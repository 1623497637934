import {inject, Injectable} from '@angular/core';
import {SsrCookieService} from "ngx-cookie-service-ssr";

@Injectable()
export class MemoryStorage implements Storage {

  private _cookieService = inject(SsrCookieService)

  constructor() {
    this['data'] = JSON.parse(this._cookieService.get("cookieStorage") || '{}');
  }

  [name: string]: any;

  get length(): number {
    return Object.keys(this).length;
  }

  clear(): void {
    this._cookieService.delete("cookieStorage");
    this['data'] = {};
  }

  getItem(key: string): string | null {
    return key in this['data'] ? this['data'][key] : null;
  }

  key(index: number): string | null {
    const keys = Object.keys(this['data']);

    return index >= 0 && keys.length < index ? keys[index] : null;
  }

  removeItem(key: string): void {
    delete this['data'][key];
  }

  setItem(key: string, value: string): void {
    this['data'][key] = value;
    this._cookieService.set("cookieStorage", JSON.stringify(this['data']));
  }
}
