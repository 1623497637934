import {EnvironmentProviders, Provider} from "@angular/core";
import {defaultNotificationConfig, NOTIFICATION_CONFIG_TOKEN} from "./notification.config";

export const provideNotifications = (): Array<Provider | EnvironmentProviders> =>
{
  return [
    {
      provide: NOTIFICATION_CONFIG_TOKEN, useValue: defaultNotificationConfig
    }
  ];
};
