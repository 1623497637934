import {ChangeDetectionStrategy, Component, ElementRef, ViewChild} from '@angular/core';
import {FieldTypeConfig, FormlyModule} from '@ngx-formly/core';
import {FieldType, FormlyMaterialModule} from '@ngx-formly/material';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ContentEditableValueAccessor} from "../contenteditable/content-editable-value-accessor.directive";
import {MatInputModule} from "@angular/material/input";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-title-field',
  templateUrl: './title-field.component.html',
  styleUrls: ['./title-field.component.scss'],
  standalone: true,
  imports: [
    FormlyModule, ReactiveFormsModule, FormsModule, ContentEditableValueAccessor, FormlyMaterialModule, MatInputModule, NgIf
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleFieldComponent extends FieldType<FieldTypeConfig> {

  @ViewChild('editor') contentEditable: ElementRef<HTMLInputElement> | undefined;

  onPaste(e: Event) {
    e.preventDefault();

    // Get pasted data via clipboard API
    const clipboardData = (e as ClipboardEvent).clipboardData;
    const pastedText = clipboardData?.getData('text/plain');

    if (pastedText) {
      // Insert the plain text at the cursor position
      this.insertTextAtCursor(pastedText);
    }

    this.formControl?.setValue(this.contentEditable?.nativeElement.innerText);
    this.formControl?.markAsDirty();
    e.stopPropagation()
  }

  insertTextAtCursor(text: string) {
    const selection = window.getSelection();
    if (!selection?.rangeCount) {
      return;
    }

    // Get the currently selected range
    const range = selection.getRangeAt(0);
    range.deleteContents(); // Remove the selected text if any
    const textNode = document.createTextNode(text);

    // Insert the text at the cursor
    range.insertNode(textNode);

    // Move the cursor after the inserted text
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);

    // Ensure the selection is updated to place the cursor correctly
    selection.removeAllRanges();
    selection.addRange(range);
  }
}
