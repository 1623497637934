@if (mode() === "button") {
  <button aria-label="Przełącz tryb ciemny"
          class="icon-btn"
          (click)="toggle()"
  >
    @if (darkMode()) {
      <mat-icon
        [svgIcon]="'mat_outline:dark_mode'"></mat-icon>
    } @else {
      <mat-icon
        [svgIcon]="'mat_outline:light_mode'"></mat-icon>
    }
  </button>
} @else {
  <button [class.bg-teal-900]="darkMode()" [class.bg-teal-600]="!darkMode()"
          class="dark-mode-switch"
          role="switch" type="button" (click)="toggle()">
    <span class="sr-only">Włącz tryb ciemny</span>
    <span [class.translate-x-5]="darkMode()" [class.translate-x-0]="!darkMode()" class="switch"></span>
  </button>
}
