<div class="app-content">
  <app-toolbar></app-toolbar>
  <app-sidenav class="bg-white dark:bg-neutral-900"></app-sidenav>
  <mat-sidenav-container
    class="sidenav-container"
    [hasBackdrop]="true">
    <mat-sidenav-content class="sidenav-content">
      <app-loading-bar></app-loading-bar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-footer></app-footer>
</div>
