import {Component, ViewEncapsulation} from '@angular/core';
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-single-form',
  standalone: true,
    imports: [
        RouterOutlet
    ],
  templateUrl: './single-form.component.html',
  styleUrl: './single-form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SingleFormComponent {

}
