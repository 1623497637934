import {
  effect,
  inject,
  PLATFORM_ID,
} from "@angular/core";
import {LocalStorage} from "./local-storage/local-storage";
import {isPlatformBrowser} from "@angular/common";
import {patchState, signalStore, withHooks, withMethods, withState} from "@ngrx/signals";

export type AppSettings = {
  darkMode: boolean
  displayConsent: boolean
}

const defaultSettings: AppSettings = {
  darkMode: false,
  displayConsent: true
}

export const AppSettingsStore = signalStore({providedIn: 'root'},
  withState(defaultSettings),
  withMethods((store, localStorage = inject(LocalStorage), platformId = inject(PLATFORM_ID)) => ({
    toggleDarkMode() {
      patchState(store, {darkMode: !store.darkMode()})

      if (isPlatformBrowser(platformId)) {
        localStorage.setItem('appSettings', JSON.stringify(store))
      }
    },

    acceptConsent() {
      patchState(store, {displayConsent: false})

      if (isPlatformBrowser(platformId)) {
        localStorage.setItem('appSettings', JSON.stringify(store))
      }
    },

    loadSettings() {
      if (isPlatformBrowser(platformId)) {
        let storageItem = localStorage.getItem('appSettings');
        let appSettings = storageItem ? JSON.parse(storageItem) : defaultSettings;

        patchState(store, appSettings)
      }
    },
    saveSettings() {
      if (isPlatformBrowser(platformId)) {
        localStorage.setItem('appSettings', JSON.stringify({
          darkMode: store.darkMode().valueOf(),
          displayConsent: store.displayConsent().valueOf()
        }))
      }
    }
  })),
  withHooks((store) => ({
    onInit() {
      store.loadSettings();

      effect(() => {
        store.saveSettings();
      });
    }
  }))
)
