<div class="content-container">
  <div class="content !max-w-screen-md">
    <quill-editor class="w-full" [formats]="formats" (onEditorCreated)="onCreate($event)"
                  [placeholder]="props.placeholder || 'Wprowadź treść'" [modules]="modules" [theme]="'bubble'"
                  [formlyAttributes]="field" [formControl]="formControl" [class.is-invalid]="showError"></quill-editor>

    <div class="error-container">
      <mat-error *ngIf="!field.formControl.untouched && field.formControl.invalid">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </div>
  </div>
</div>



