import {Routes} from '@angular/router';
import { BaseLayoutComponent } from '../layout/base-layout/base-layout.component';
import { SingleFormComponent } from '../layout/single-form/single-form.component';
import {AuthGuard} from "../core/auth/guards/auth.guard";
import {PublicProfileComponent} from "../user/public-profile/public-profile.component";

export const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('../pages/pages.routes') },
    ]
  },
  {
    path: '',
    component: SingleFormComponent,
    children: [
      {path: '', loadChildren: () => import('../core/auth/auth.routes')},
    ]
  },
  {
    path: 'utwory',
    children: [
      { path: '', loadChildren: () => import('../content/content.routes') },
    ]
  },
  {
    path: 'uzytkownik/:username/profil',
    component: BaseLayoutComponent,
    children: [
      { path: '', component: PublicProfileComponent },
    ]
  },
  {
    path: 'konto',
    children: [
      { path: '', loadChildren: () => import('../user/user.routes'), canActivate: [AuthGuard] },
    ]
  },
  {
    path: '**',
    redirectTo: 'errors/404'
  }
];
